::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar track */
  }
  
  /* Customize the scrollbar thumb (the draggable part) */
  ::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 5px; /* Rounded corners for the thumb */
  }
  
  /* Customize the scrollbar track on hover */
  ::-webkit-scrollbar-track:hover {
    background: #ccc; /* Color of the track on hover */
  }
  
  /* Customize the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the thumb on hover */
  }
  
  /* Customize the scrollbar corner (where vertical and horizontal scrollbars meet) */
  ::-webkit-scrollbar-corner {
    background: #fff; /* Color of the scrollbar corner */
  }
  
  /* Customize the scrollbar button (the arrows for scrolling) */
  ::-webkit-scrollbar-button {
    display: none; /* Hide scrollbar buttons */
  }